import {
  ALERT,
  BASKET_SERVICE_TYPE,
  DAYS_OF_WEEK,
  EXCEED_AMOUNTS,
  FNS_DATE_FORMAT,
  PRINTER_MESSAGE,
  RESPONSE_STATUS,
  GENERIC_ERROR,
} from 'globalConstants';
import {
  ALERT_MESSAGES,
  CUSTOMER_KYC_STATUS,
  CUSTOMER_PROFILE_LABEL,
  DOB_ALLOWEd_AGE,
  DOB_ERROR_MESSAGE,
  INVALID_DATE_MESSAGE,
  PROFILE_STATUS,
  PURCHASE_STATUS,
} from 'components/customer/constants';
import {
  STOCK_TRANSFER_ERROR,
  STOCK_TRANSFER_STATUS,
} from 'components/inventoryManagement/constants';
import { differenceInBusinessDays, format, getDay } from 'date-fns';
import { isArrayBuffer, cloneDeep, isEmpty, isNaN, isNull, isUndefined } from 'lodash';

import atob from 'atob';
import moment from 'moment';
import { notification } from 'antd';

import { LOAN_STATUS } from 'components/pickupRenew/constants';
import { sendToPrinter } from 'services/user';

export const removeHTMLTags = (strValue: string): string => {
  if (strValue === null || strValue === '' || strValue === undefined) return '';
  return strValue.replace(/(<([^>]+)>)/gi, '');
};

export const joinValues = (
  values: string[],
  includeWhitespace = false
): string => {
  const nonNullValues = values.filter((n) => n);
  return includeWhitespace ? nonNullValues.join(' ') : nonNullValues.join(', ');
};

export const b64toBlob = (dataURI: any) => {
  const byteString = atob(dataURI.split(',')[1]);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: 'image/jpeg' });
};

export const getTimestamp = (dateString: string) => {
  return Date.parse(dateString);
};

export const reducePledgeItems = (data: any) => {
  const items = data?.reduce((acc: any, item: any) => {
    const obj: object | any = {};
    Object.keys(item).map((key) => {
      return (obj[key] = item[key]?.id === 0 ? 0 : item[key]?.id || item[key]);
    });
    if (!isEmpty(obj)) return [...acc, obj];
    else return [...acc];
  }, []);
  return items;
};

//to test all keys inside object are undefined
export const isEmptyObject = (obj: any) => {
  const result = Object.values(obj).every((o) => o === undefined);
  return result;
};

export const currencyFormat = (x: number | string, isGBP = false) => {
  if (!isUndefined(x) && !isNull(x)) {
    const numSign = Math.sign(+x);
    let formattedVal = toFixedNoRound(Math.abs(+x))
      ?.toString()
      ?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');

    if (isGBP) {
      formattedVal = ' £' + formattedVal;
    }
    if (numSign === -1) {
      formattedVal = '-' + formattedVal;
    }
    return formattedVal;
  }
};

export const toFixedNoRound = (x: number | string, fixedToDecimal = 2) => {
  if (!isUndefined(x) && !isNull(x)) {
    const pattern = new RegExp(
      '^-?\\d+(?:.\\d{0,' + (fixedToDecimal || -1) + '})?'
    );
    return Number(x?.toString()?.match(pattern))?.toFixed(fixedToDecimal);
  }
};

export const filterBasketItemsOnServiceType = (
  basket: any,
  serviceType: number
) => {
  const filteredItems = !isEmpty(basket)
    ? basket?.basketServices.filter(
        (item: any) => item.serviceType === serviceType
      )
    : [];
  return filteredItems;
};

export const filterBasketItemsOnServiceId = (
  basket: any,
  serviceId: number
) => {
  const filteredItems = !isEmpty(basket)
    ? basket?.basketServices.filter((item: any) => item.serviceId === serviceId)
    : [];
  return filteredItems;
};

export const calculateTotalSum = (items: any) => {
  return items.reduce((a: number, b: number) => a + b, 0);
};

/** filter loan amount as per different-2 basket item like pledge, retail */
export const allLoanAmounts = (basketItems: Array<any>, keyType: string) => {
  keyType = keyType.toLowerCase();
  const total = basketItems.reduce(
    (acc: any, item: any) => {
      const totalItemsCount =
        item[keyType]?.request?.items?.length || basketItems.length;
      const totalLoanAmount = item.serviceAmount;

      return {
        totalItemsCount: totalItemsCount || 0,
        totalAmounts: acc.totalAmounts + totalLoanAmount,
        totalAmountPerTabs: [
          ...acc.totalAmountPerTabs,
          Math.abs(totalLoanAmount),
        ],
        innerTabsCount: basketItems.length,
      };
    },
    {
      totalItemsCount: 0,
      totalAmounts: 0,
      innerTabsCount: 0,
      totalAmountPerTabs: [],
    }
  );
  total.totalAmounts = Math.abs(total.totalAmounts);
  return total;
};

export const calculateTotalSumByKey = (arr: Array<any>, key: string) => {
  return arr?.reduce((a: number, b: any) => a + (+b[key] || 0), 0);
};

export const formatDayAndDate = (date: any) => {
  if (date) {
    const dueDate = format(new Date(date), FNS_DATE_FORMAT);
    const dueDay = DAYS_OF_WEEK[getDay(new Date(date))];

    return `${dueDay}, ${dueDate}`;
  }
};

export const dateDisplayFormat = (
  dateStr: any,
  dateFormat: string = FNS_DATE_FORMAT
) => {
  try {
    if (dateStr) {
      const formatedDate = format(new Date(dateStr), dateFormat);
      return formatedDate;
    }
    return '';
  } catch (e: any) {
    return dateStr;
  }
};

export const findValueByKey = (obj: object, keyType: any) => {
  let result = '';
  for (const [key, value] of Object.entries(obj)) {
    if (key === keyType) {
      result = value;
    }
  }
  return result;
};

export const reduceWatchHandbagItems = (data: any) => {
  const obj = Object.keys(data).map((key) => {
    return {
      attributeId: key,
      value: data[key],
    };
  });
  return obj;
};

export const getWatchHandbagFormItem = (data: any) => {
  const result = data.reduce(
    (obj: any, item: { attributeId: any; value: any }) =>
      Object.assign(obj, { [item.attributeId]: item.value }),
    {}
  );
  return result;
};

export const isSoldDateExceed = (
  transactionDate: any,
  numberOfDays: number
) => {
  return (
    differenceInBusinessDays(Date.now(), getTimestamp(transactionDate)) >
    numberOfDays
  );
};

//convert different services item to an item array to render in the pickup/renew page
export const getPledgeAgreementItemsArray = (items: any) => {
  return items
    .filter((x: any) => x.serviceType === BASKET_SERVICE_TYPE.PICKUPRENEW)
    .map((item: any) => {
      return item.pickupRenew.request.item;
    });
};

export const getCurrencySymbol = (
  currencies: Array<any>,
  currencyCode: string
) => {
  return currencies?.find((item) => item.currencyCode === currencyCode)
    ?.currencySymbol;
};

export const getCustomerKycStatusIcon = (profileStatus: any) => {
  switch (profileStatus) {
    case 'green':
      return require('../assets/images/tick_green.svg').default;
    case 'red':
      return require('../assets/images/block_ic.svg').default;
    case 'yellow':
      return require('../assets/images/account_updt_ic.svg').default;
    case 'purple':
      return require('../assets/images/monitored_account_purple.svg').default;
    case 'amber':
      return require('../assets/images/monitored_account_amber.svg').default;
    case 'black':
      return require('../assets/images/monitored_account_black.svg').default;
    default:
      return '';
  }
};

export const getProfileLabel = (profileStatus: any) => {
  switch (profileStatus) {
    case CUSTOMER_KYC_STATUS[1]:
      return CUSTOMER_PROFILE_LABEL.GREEN;
    case CUSTOMER_KYC_STATUS[2]:
      return CUSTOMER_PROFILE_LABEL.RED;
    case CUSTOMER_KYC_STATUS[3]:
      return CUSTOMER_PROFILE_LABEL.YELLOW;
    case CUSTOMER_KYC_STATUS[0]:
      return CUSTOMER_PROFILE_LABEL.PURPLE;
    case CUSTOMER_KYC_STATUS[4]:
      return CUSTOMER_PROFILE_LABEL.AMBER;
    case CUSTOMER_KYC_STATUS[5]:
      return CUSTOMER_PROFILE_LABEL.BLACK;
    default:
      return CUSTOMER_PROFILE_LABEL.ERROR;
  }
};

export const createWindowURL = (id: any, data: any, page: string) => {
  const url = window.URL.createObjectURL(
    new Blob([data], { type: 'application/pdf' })
  );
  const pdfFrame = document.body.appendChild(document.createElement('iframe'));
  pdfFrame.style.display = 'none';
  pdfFrame.onload = () => void pdfFrame?.contentWindow?.print();
  pdfFrame.src = url;
};

export const getPurchaseReturnItems = (items: any) => {
  return items
    .filter((x: any) => x.serviceType === BASKET_SERVICE_TYPE.PURCHASERETURN)
    .map((item: any) => {
      return item.purchaseReturn.request.item;
    });
};

//Check if amount is exceed then return true as per service when guest screen
export const isAmountExceed = (basket: any) => {
  let isValueExceed = false;
  const retailService = basket?.basketServices?.filter(
    (x: any) => x.serviceType === BASKET_SERVICE_TYPE.RETAIL
  );
  const allItems = retailService?.reduce((acc: any, item: any) => {
    return [...acc, ...item.retail.request.items];
  }, []);

  //If service type retail and cost value is exeeded
  isValueExceed = allItems?.some(
    (item: any) => item.costValue > EXCEED_AMOUNTS.RETAIL && !item.isNew
  );

  //MP:5833 - Commenting FX check in case of futrue use
  // const fxService = basket?.basketServices?.filter(
  //   (x: any) => x.serviceType === BASKET_SERVICE_TYPE.FX
  // );
  // if (fxService && !isValueExceed) {
  //   //If service type FX and total amount is exceeded (buy currency +ve amount) and If in case of sell currency if (Pay to Customer -ve amount) value exceed
  //   isValueExceed =
  //     basket?.totalFX?.totalFXAmount > EXCEED_AMOUNTS.FX ||
  //     basket?.totalFX?.totalFXAmount < -EXCEED_AMOUNTS.FX;
  // }

  return isValueExceed;
};

export const getPurchaseStatus = (
  quarantineStatus: number,
  isReturned: boolean
) => {
  switch (quarantineStatus) {
    case 0:
      if (!isReturned) {
        return PURCHASE_STATUS.PFI;
      } else {
        return PURCHASE_STATUS.RETURNED;
      }
    case 1:
      if (isReturned) {
        return PURCHASE_STATUS.RETURNED;
      } else {
        return PURCHASE_STATUS.HOLD;
      }
    default:
      return PURCHASE_STATUS.HOLD;
  }
};

export const checkAvailableCurrencies = (
  availableCurr: any,
  orderedCurr: any
) => {
  const result = availableCurr?.reduce(
    (acc: any, item: any) => {
      const odrCurr = orderedCurr?.find(
        (o: any) => o.currency === item.currency
      );
      const isFxAvailable = item.amount >= odrCurr?.amount;
      if (!isFxAvailable) {
        return {
          ...acc,
          currencies: [...acc.currencies, item],
          isCurrenciesNotAvailable: true,
        };
      }
      return acc;
    },
    { isCurrenciesNotAvailable: false, currencies: [] }
  );
  return result;
};

export const disabledFutureDate = (currentDate: any) => {
  return currentDate && currentDate > moment().endOf('day');
};

export const capitalize = (str: string) => {
  if (str.length > 1) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return str;
};

export const validateDateOfBirth = (
  _: any,
  value: any,
  allowedMinAge = DOB_ALLOWEd_AGE.MIN_AGE,
  allowedMaxAge = DOB_ALLOWEd_AGE.MAX_AGE
) => {
  const validAge = moment().diff(value, 'years', false);

  if (isNaN(validAge)) {
    return Promise.reject(new Error(INVALID_DATE_MESSAGE));
  }
  if (validAge < allowedMinAge) {
    return Promise.reject(
      new Error(
        DOB_ERROR_MESSAGE.MIN_AGE_PRE_TEXT +
          allowedMinAge +
          DOB_ERROR_MESSAGE.MIN_AGE_POST_TEXT
      )
    );
  }
  if (validAge > allowedMaxAge) {
    return Promise.reject(new Error(DOB_ERROR_MESSAGE.GREATER_THAN_150));
  }
  return Promise.resolve();
};

export const getPledgePurchaseChildTab = (
  basket: any,
  activeService: any,
  targetServiceId: any
) => {
  const services = basket?.basketServices?.filter(
    (x: any) => x.serviceType === +activeService
  );

  if (targetServiceId) {
    const serviceIndex = services?.findIndex(
      (service: any) => service.serviceId === targetServiceId
    );

    return serviceIndex + 1;
  } else return services.length;
};

export const calculateTotalWeight = (arr?: Array<any>) => {
  const totalWeight =
    arr && arr.length > 0
      ? arr.reduce((a: number, b: any) => {
          //commenting the code - MP-6282, MP-6289, EVB-774
          // if (b['loanItemType']['id'] === +BASE_PLEDGE_CATEGORIES[2].id) {
          //   return (
          //     // a + b['itemValuation']['coinPrice']['weight'] + b['totalWeight']
          //     a + b['itemValuation']['coinPrice']['weight']
          //   );
          // } else
          return a + b['totalWeight'];
        }, 0)
      : 0;

  return totalWeight;
};

export const getParentImageUrl = (imageCollection: any) => {
  const parentImage = imageCollection?.images?.find(
    (obj: any) => obj.isDefault === true
  );

  if (parentImage)
    return `/api/items/image/${parentImage.containerId}/${parentImage.blobId}?isThumnails=false`;
  return '';
};

export const getLowercaseWithoutSpace = (value: string) => {
  if (value) return value?.toLowerCase().replace(/\s/g, '');
  return '';
};

export const getObjectKeyForValue = (obj: any, valueString: any) => {
  for (let [key, value] of Object.entries(obj))
    if (value === valueString) return key;
  return 0;
};

export const getPledgeIcons = (row: any, vulnerabilityStatus: string = '', showChangeOwnershipIcon = false) => {
  // 1 - Confiscated
  // 2 - Vulnerable
  let statusIcons = []
  const getIcons = (row: any, vulnerabilityStatus: string) => {
    switch (row?.confiscated) {
      case 0:
      case 2:
        return getStatusIconValue(row, vulnerabilityStatus);
      case 1:
        return [LOAN_STATUS.CONFISCATED, null];
      //removing this using Consumer Duty Implementation
      // case 2:
      //   return [LOAN_STATUS.VULNERABLE, null];
      default:
        return [row.loanStatus, null];
    }
  }
  
  statusIcons = getIcons(row, vulnerabilityStatus)
  if(showChangeOwnershipIcon && row?.isOwnerShipChanged) statusIcons.push(LOAN_STATUS.CHANGE_OWNERSHIP)
  return statusIcons

};

const getStatusIconValue = (row: any, vulnerabilityStatus: string) => {
  if (
    row?.loanStatus === LOAN_STATUS.PICKED_UP &&
    (row?.isWithdraw || row?.isExistingWithdrawNotice)
  )
    return [LOAN_STATUS.WITHDRAWN, null];

  if (row?.loanStatus === LOAN_STATUS.OPEN) {
    if (vulnerabilityStatus === PROFILE_STATUS.BLACK || row?.isLocked) {
      let icons = [];

      if (row?.pledgeServicePermissions) {
        let { renewal, paydown, partialPayment, pfiAuction } =
          row?.pledgeServicePermissions;

        if (renewal && paydown && partialPayment && pfiAuction)
          return [LOAN_STATUS.OPEN];
        else if (!renewal && !paydown && !partialPayment && !pfiAuction)
          return [LOAN_STATUS.LOCKED];
        else {
          if (renewal) icons.push(LOAN_STATUS.RENEWAL);
          if (paydown) icons.push(LOAN_STATUS.PAYDOWN);
          if (partialPayment) icons.push(LOAN_STATUS.PARTIAL_PAY);
          if (pfiAuction) icons.push(LOAN_STATUS.PFI_AUCTION);
          return [LOAN_STATUS.LOCKED, ...icons];
        }
      }

      return [LOAN_STATUS.LOCKED];
    }

    if (row?.pledgeServicePermissions) {
      let icons = [];

      let { renewal, paydown, partialPayment } = row?.pledgeServicePermissions;

      if (renewal && paydown && partialPayment) {
        if (
          typeof row?.affidavitFees?.reasonId !== 'undefined' &&
          row?.affidavitFees?.reasonId !== null
        ) {
          icons.push(LOAN_STATUS.AFFIDAVIT_ICON);
          return [LOAN_STATUS.OPEN, ...icons];
        }
        return [LOAN_STATUS.OPEN];
      } else if (!renewal && !paydown && !partialPayment)
        return [LOAN_STATUS.IS_AT_RISK];
      else {
        if (renewal) icons.push(LOAN_STATUS.RENEWAL);
        if (paydown) icons.push(LOAN_STATUS.PAYDOWN);
        if (partialPayment) icons.push(LOAN_STATUS.PARTIAL_PAY);
        return [LOAN_STATUS.IS_AT_RISK, ...icons];
      }
    }

    //fallback status for CST - In case pledgeServicePermissions comes up empty
    if (vulnerabilityStatus === PROFILE_STATUS.AMBER || row?.isAtRisk)
      return [LOAN_STATUS.IS_AT_RISK];

    if (row?.isRequestedForWithdraw || row?.isExistingWithdrawNotice)
      return [LOAN_STATUS.OPEN, LOAN_STATUS.WITHDRAW_LATER];

    if (row?.isEarlySettlement || row?.isEarlySettlementRaised)
      return [LOAN_STATUS.OPEN, LOAN_STATUS.EARLY_SETTLEMENT];
  }

  if (row?.loanStatus === LOAN_STATUS.OPEN && row?.loanstatuses?.length) {
    return [LOAN_STATUS.OPEN, ...row?.loanstatuses];
  }

  if (
    (row?.affidavitAmount === 0 &&
      typeof row?.affidavitFees?.reasonId !== 'undefined' &&
      row?.affidavitFees?.reasonId !== null) ||
    row?.waiveInterest?.isWaivedOffAffidavitAmount
  )
    return [row.loanStatus, LOAN_STATUS.AFFIDAVIT_ICON];

  return [row.loanStatus, null];
};

export const arrayBufferTob64 = (data: any) =>
  btoa(
    new Uint8Array(data).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      ''
    )
  );

export const createArrayChunks = (arr: any, size: number) => {
  if(!arr || arr?.lenght === 0) return []
  if(arr?.lenght === 1) return arr
  
  return Array?.from({ length: Math.ceil(arr?.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );
};

export const sortArrayByKey = (
  arr: Array<any>,
  objKey: string,
  order: boolean = false
) => {
  var sortedArray = [...arr];
  sortedArray?.sort((a: any, b: any) =>
    a[objKey] < b[objKey] ? 1 : a[objKey] > b[objKey] ? -1 : 0
  );

  // order: true -> ascending order
  if (order) {
    const orderedArr = cloneDeep(sortedArray).reverse();
    return orderedArr;
  }

  return sortedArray;
};

// rate-modification utils
export const calculateInterestRateFromRateBands = (
  totalAmount: number,
  rateBands: Array<any>
) => {
  if (totalAmount) {
    let rateDetailsForAmount = rateBands.find(
      (obj) => obj.loanAmountUpperLimit >= totalAmount
    );

    return rateDetailsForAmount;
  }

  return {};
};

export const getNotificationAlerts = (errorCode: number, type: string) => {
  switch (errorCode) {
    case RESPONSE_STATUS.NOT_FOUND:
      return type === STOCK_TRANSFER_STATUS.CLOSED
        ? STOCK_TRANSFER_ERROR.NO_CLOSE_TRANSFER_FOUND
        : STOCK_TRANSFER_ERROR.NO_ACTIVE_TRANSFER_FOUND;
    default:
      return GENERIC_ERROR;
  }
};

export const arrayBufferToJSON = (data: any) => {
  if (!isArrayBuffer(data)) return {};
  return JSON.parse(new TextDecoder().decode(data));
};

export const callPrinter = (
  pdfData: any,
  type: any,
  filename: any,
  showNotification = false,
  notificationMessage = PRINTER_MESSAGE.PRINT_SUCCESS,
  options = {}
) => {
  if (pdfData?.data) {
    const printStatus = sendToPrinter(pdfData, type, filename, options);
    if (showNotification) {
      getNotification(ALERT.STATUS.SUCCESS, notificationMessage, '');
    }
    return printStatus;
  }
};

export const getNotification = (
  type: any,
  message: any,
  description = '',
  duration = ALERT.DURATION.GENERIC
) => {
  var params = {
    message: message,
    description: description,
    duration: duration,
  };
  type === ALERT.STATUS.SUCCESS
    ? notification.success(params)
    : type === ALERT.STATUS.ERROR
    ? notification.error(params)
    : notification.warning(params);
};

export const getGenericError = (errorMsg = GENERIC_ERROR) => {
  getNotification(ALERT.STATUS.ERROR, errorMsg);
};

export const getErrorSuccessNotification = (
  actions: any,
  dispatch: any,
  isErrorVisible: any,
  error = '',
  isSuccessMsgVisible: any,
  successMsg = ''
) => {
  if (isErrorVisible) {
    getNotification(ALERT.STATUS.ERROR, error);
    dispatch(actions.setIsErrorVisible(false));
  }

  if (isSuccessMsgVisible) {
    getNotification(ALERT.STATUS.SUCCESS, successMsg);
    dispatch(actions.setIsSuccessMsgVisible(false));
  }
}


