import * as urls from '../apiUrls';
import { doRequest } from 'utils/request';
import { b64toBlob } from 'utils/util';
import { AGREEMENT_OPTIONS } from 'components/pickupRenew/constants';

const getPledgeCategory = (category) => {
  return doRequest(`${urls.GET_ITEM_CATEGORY}/${category}`, 'get');
};

const getPledgeCategoryFixedAttributes = () => {
  return doRequest(`${urls.GET_ITEM_FIXED_ATTRIBUTES}`, 'get');
};

const getItemCategoryAttributes = (id) => {
  return doRequest(`${urls.GET_ITEM_CATEGORY_ATTRIBUTES}/${id}`, 'get'); // for watches / handbags
};

const getItemCategoryChecklist = (id) => {
  return doRequest(`${urls.GET_ITEM_CATEGORY_CHECKLIST}/${id}`, 'get'); // for watches / handbags
};

const getItemPrice = (params) => {
  return doRequest(`${urls.GET_ITEM_PRICE}`, 'post', params);
};

const getPledgeInterestRate = () => {
  return doRequest(`${urls.GET_INTEREST_RATE}`, 'get');
};

const getLoanPayableAmount = (params) => {
  return doRequest(`${urls.GET_LOAN_PAYABLE_AMT}`, 'post', params);
};

//customer pledge summary tab data
const getPledgeSummary = (id, skip, take) => {
  if (take) {
    return doRequest(
      `${urls.GET_PLEDGE_SUMMARY}/${id}`,
      'get',
      {
        skip: skip,
        take: take,
      },
      '',
      true
    );
  } else {
    return doRequest(`${urls.GET_PLEDGE_SUMMARY}/${id}`, 'get');
  }
};

//customer purchase summary tab data
const getPurchaseSummary = (id) => {
  return doRequest(`${urls.GET_PURCHASE_SUMMARY}/${id}`, 'get');
};

//customer pledge overview
const getPledgeOverview = (customerId) => {
  return doRequest(`${urls.GET_PLEDGE_OVERVIEW}/${customerId}`, 'get');
};

const getPledgeAgreement = (searchKeyword) => {
  var encodedKeyword = encodeURIComponent(searchKeyword);
  return doRequest(`${urls.GET_PLEDGE_AGREEMENT}/${encodedKeyword}`, 'get');
};

const getPledgeAgreementItems = (agreementNumber) => {
  return doRequest(
    `${urls.GET_PLEDGE_AGREEMENT_ITEMS}/${agreementNumber}`,
    'get'
  );
};

const getPurchaseItems = (agreementNumber, isReturned = 0) => {
  return doRequest(
    `${urls.GET_PURCHASE_ITEMS}/${agreementNumber}/${isReturned}`,
    'get'
  );
};

const getPaidPledgePaymentDetails = (agreementNumber) => {
  return doRequest(
    `${urls.GET_PAID_PLEDGE_PAYMENT_DETAILS}/${agreementNumber}`,
    'get'
  );
};

const getFAQData = () => {
  return doRequest(`${urls.GET_FAQ_DATA}`, 'get');
};

const calculatePledgeAgreementAmount = (params) => {
  switch (params.selectedActionType) {
    case AGREEMENT_OPTIONS[0].id:
      return doRequest(`${urls.CALCULATE_LOAN_PICKUP_AMT}`, 'post', params);
    case AGREEMENT_OPTIONS[1].id:
      return doRequest(`${urls.CALCULATE_LOAN_RENEW_AMT}`, 'post', params);
    case AGREEMENT_OPTIONS[2].id:
      return doRequest(`${urls.CALCULATE_LOAN_PAYDOWN_AMT}`, 'post', params);
    case AGREEMENT_OPTIONS[3].id:
      return doRequest(`${urls.CALCULATE_LOAN_PARTIAL_AMT}`, 'post', params);
    case AGREEMENT_OPTIONS[4].id:
      return doRequest(`${urls.CALCULATE_LOAN_WITHDRAW_AMT}`, 'post', params);
    case AGREEMENT_OPTIONS[5].id:
      return doRequest(`${urls.CALCULATE_LOAN_AFFIDAVIT_AMT}`, 'post', params);
    default:
      return;
  }
};

//customer purchase overview
const getPurchaseOverview = (storeId, customerId) => {
  return doRequest(
    `${urls.GET_PURCHASE_OVERVIEW}/${storeId}/${customerId}`,
    'get'
  );
};

const getAffidavitReasons = () => {
  return doRequest(`${urls.AFFIDAVIT_REASONS}`, 'get');
};

const getPledgeHistory = (loanId) => {
  return doRequest(`${urls.GET_PLEDGE_HISTORY}/${loanId}`, 'get');
};

const getWithdrawNotificationMethod = () => {
  return doRequest(`${urls.GET_METHOD_OF_NOTIFICATIONS}`, 'get');
};

const getWithdrawLater = (loanNumber) => {
  return doRequest(`${urls.GET_WITHDRAW_LATER}/${loanNumber}`, 'get');
};

const postWithdrawLater = (params) => {
  return doRequest(
    `${urls.POST_WITHDRAW_LATER}`,
    'post',
    params,
    'application/json',
    false,
    'arraybuffer'
  );
};

const getEarlySettlement = (loanNumber) => {
  return doRequest(`${urls.GET_EARLY_SETTLEMENT}/${loanNumber}`, 'get');
};

const postEarlySettlement = (params) => {
  return doRequest(
    `${urls.POST_EARLY_SETTLEMENT}`,
    'post',
    params,
    'application/json',
    false,
    'arraybuffer'
  );
};

const uploadImageCollection = (image, params, isDefault) => {
  let blob = b64toBlob(image);
  let formData = new FormData();

  formData.append('file', blob);
  formData.append('isDefault', isDefault);
  formData.append('fileCollectionId', params?.fileCollectionId);
  formData.append('fileCollections', JSON.stringify(params));

  return doRequest(
    `${urls.UPLOAD_IMAGE_COLLECTION}`,
    'post',
    formData,
    'multipart/form-data'
  );
};

const getPurchaseDetail = (purchaseId) => {
  return doRequest(`${urls.GET_PURCHASE_DETAIL}/${purchaseId}`, 'get');
};

const getPurchaseItemDetail = (purchaseId) => {
  return doRequest(`${urls.GET_PURCHASE_ITEM_DETAIL}/${purchaseId}`, 'get');
};

const calculatePurchaseReturnAmt = (params) => {
  return doRequest(`${urls.CALCULATE_PURCHASE_RETURN_AMT}`, 'post', params);
};

const searchPledgeAgreements = (params) => {
  return doRequest(`${urls.SEARCH_PLEDGE_AGREEMENTS}`, 'post', params);
};

const searchHistoricalItems = (customerId) => {
  return doRequest(`${urls.SEARCH_HISTORICAL_ITEMS}/${customerId}`, 'get');
};

const addHistoricalItems = (itemId) => {
  return doRequest(`${urls.ADD_HISTORICAL_ITEMS}/${itemId}`, 'get');
};

const getCustomerSurplusData = (customerId) => {
  return doRequest(`${urls.GET_SURPLUS_FOR_CUSTOMER}/${customerId}`, 'get');
};

const calculateSurplusData = (params) => {
  return doRequest(`${urls.CALCULATE_LOAN_SURPLUS}`, 'post', params);
};

const postValuationRequest = (params) => {
  return doRequest(`${urls.POST_VALUATION_REQUEST}`, 'post', params);
};

const getItemCategoryAllAttributes = (id) => {
  return doRequest(`${urls.GET_ITEM_CATEGORY_ALL_ATTRIBUTES}/${id}`, 'get'); // for all categories - call this endpoint on last selection on create pledge
};

const getRateModificationReasons = () => {
  return doRequest(urls.GET_RATE_MODIFICATION_REASONS, 'get');
};

const getFirstPickupPrompt = (customerId, loanNumber) => {
  return doRequest(
    `${urls.GET_FIRST_PICKUP_PROMPT}/${customerId}/${loanNumber}`,
    'get'
  );
};

export {
  getPledgeCategory,
  getPledgeCategoryFixedAttributes,
  getItemCategoryAttributes,
  getItemCategoryChecklist,
  getItemPrice,
  getPledgeInterestRate,
  getLoanPayableAmount,
  getPledgeSummary,
  getPurchaseSummary,
  getPledgeOverview,
  getPledgeAgreement,
  getPledgeAgreementItems,
  getPurchaseItems,
  getPaidPledgePaymentDetails,
  calculatePledgeAgreementAmount,
  getPurchaseOverview,
  getAffidavitReasons,
  getPledgeHistory,
  getWithdrawNotificationMethod,
  postWithdrawLater,
  uploadImageCollection,
  getPurchaseDetail,
  getPurchaseItemDetail,
  calculatePurchaseReturnAmt,
  getWithdrawLater,
  getFAQData,
  getEarlySettlement,
  postEarlySettlement,
  searchPledgeAgreements,
  searchHistoricalItems,
  addHistoricalItems,
  getCustomerSurplusData,
  calculateSurplusData,
  postValuationRequest,
  getItemCategoryAllAttributes,
  getRateModificationReasons,
  getFirstPickupPrompt,
};
